import { lazy } from "react";
import Loadable from "../../components/Loadings/Loadable";

const MainLayout = Loadable(lazy(() => import('../../components/Layouts/MainLayout')));

// Auths
const SignInPage = Loadable(lazy(() => import('../../screens/auths/SignIn')));
const HomePage = Loadable(lazy(() => import('../../screens/mains/dashboard/Home')));

// Account
const AccountPage = Loadable(lazy(() => import('../../screens/mains/setting/account')));
const AccountFormPage = Loadable(lazy(() => import('../../screens/mains/setting/account/form')));

// Support
const SupportPage = Loadable(lazy(() => import('../../screens/mains/setting/lov')));
const SupportFormPage = Loadable(lazy(() => import('../../screens/mains/setting/lov/form')));

// Partner
const PartnerFormPage = Loadable(lazy(() => import('../../screens/mains/partner/form')));
const PartnerPage = Loadable(lazy(() => import('../../screens/mains/partner/')));
const PartnerStatusPage = Loadable(lazy(() => import('../../screens/mains/partner/status')));

// Insurance
const InsuranceFormPage = Loadable(lazy(() => import('../../screens/mains/insurance/form')));
const InsurancePage = Loadable(lazy(() => import('../../screens/mains/insurance/')));

// Customer
const CustomerFormPage = Loadable(lazy(() => import('../../screens/mains/customer/form')));
const CustomerPage = Loadable(lazy(() => import('../../screens/mains/customer')));
const SalesAnalyticsPage = Loadable(lazy(() => import('../../screens/mains/customer/analytics')));

// Pickup
const PickupMonitorPage = Loadable(lazy(() => import('../../screens/mains/pickup/index')));
const PickupInputFormPage = Loadable(lazy(() => import('../../screens/mains/pickup/input')));

// Pickup
const PickupManualListPage = Loadable(lazy(() => import('../../screens/mains/pickup-manual/index')));

// Pickup
const ProvincePage = Loadable(lazy(() => import('../../screens/mains/setting/address/province')));
const CityPage = Loadable(lazy(() => import('../../screens/mains/setting/address/city')));
const SubdistrictPage = Loadable(lazy(() => import('../../screens/mains/setting/address/subdistrict')));
const MappingPage = Loadable(lazy(() => import('../../screens/mains/setting/address/mapping')));


// Branch
const BranchPage = Loadable(lazy(() => import('../../screens/mains/setting/office/branch')));
const OfficePage = Loadable(lazy(() => import('../../screens/mains/setting/office/')));
const BranchFormPage = Loadable(lazy(() => import('../../screens/mains/setting/office/form-branch')));
const OfficeFormPage = Loadable(lazy(() => import('../../screens/mains/setting/office/form')));

// Pickup
const FinanceTopUpPage = Loadable(lazy(() => import('../../screens/mains/finance/topup')));
const BusinessReportPage = Loadable(lazy(() => import('../../screens/mains/finance')));


const appRoutes = [
    {
        path: '/',
        element: <MainLayout />,
        children: [
            { path: '', element: <HomePage /> },
        ],
    },
    {
        path: '/accounts',
        element: <MainLayout />,
        children: [
            { path: 'list', element: <AccountPage /> },
            { path: 'form', element: <AccountFormPage /> },
        ],
    },
    {
        path: '/list-of-values',
        element: <MainLayout />,
        children: [
            { path: 'list', element: <SupportPage /> },
            { path: 'form', element: <SupportFormPage /> },
        ],
    },
    {
        path: '/customers',
        element: <MainLayout />,
        children: [
            { path: 'list', element: <CustomerPage /> },
            { path: 'form', element: <CustomerFormPage /> },
            { path: 'analytics', element: <SalesAnalyticsPage /> },
        ],
    },
    {
        path: '/partners',
        element: <MainLayout />,
        children: [
            { path: 'list', element: <PartnerPage /> },
            { path: 'form', element: <PartnerFormPage /> },
            { path: 'status', element: <PartnerStatusPage /> },
        ],
    },
    {
        path: '/insurances',
        element: <MainLayout />,
        children: [
            { path: 'list', element: <InsurancePage /> },
            { path: 'form', element: <InsuranceFormPage /> },
        ],
    },
    {
        path: '/pickups',
        element: <MainLayout />,
        children: [
            { path: 'monitor', element: <PickupMonitorPage /> },
            { path: 'input', element: <PickupInputFormPage /> },
        ],
    },
    {
        path: '/finances',
        element: <MainLayout />,
        children: [
            { path: 'topup', element: <FinanceTopUpPage /> },
            { path: 'business', element: <BusinessReportPage /> },
        ],
    },
    {
        path: '/pickup-manuals',
        element: <MainLayout />,
        children: [
            { path: 'list', element: <PickupManualListPage /> },
        ],
    },
    {
        path: '/addresses',
        element: <MainLayout />,
        children: [
            { path: 'mapping', element: <MappingPage /> },
            { path: 'province', element: <ProvincePage /> },
            { path: 'city', element: <CityPage /> },
            { path: 'subdistrict', element: <SubdistrictPage /> },
        ],
    },
    {
        path: '/offices',
        element: <MainLayout />,
        children: [
            { 
                path: 'branch', 
                children: [
                    { path: 'list', element: <BranchPage /> },
                    { path: 'form', element: <BranchFormPage /> },
                ] 
            },
            { 
                path: '', 
                children: [
                    { path: 'list', element: <OfficePage /> },
                    { path: 'form', element: <OfficeFormPage /> },
                ] 
            },
        ],
    },
    {
        path: 'auths',
        children: [
            { path: 'sign-in', element: <SignInPage /> },
        ],
    }
];

export default appRoutes;